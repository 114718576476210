import { Fragment } from "react";

import { Advert } from "@vgno/adverts";

import { Teaser } from "./Teaser";

import type { Article } from "@vgno/article";

type Props = {
  advertsInsertionRules?: {
    index: number;
    placementId: string;
    subtype: "netboard";
  }[];
  articles: Article["teaser"][];
};

export const Teasers = ({ advertsInsertionRules, articles }: Props) => {
  return articles.map((article, index) => {
    if (article.contentType === "article" || article.contentType === "video") {
      const advert = advertsInsertionRules?.find(
        (advert) => advert.index === index,
      );
      return (
        <Fragment key={article.id}>
          {advert && (
            <Advert placementId={advert.placementId} subtype={advert.subtype} />
          )}
          <Teaser article={article} index={index} />
        </Fragment>
      );
    }
    return null;
  });
};
