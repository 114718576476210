import { useEffect, type LegacyRef } from "react";

import { updateAdvertory } from "@vgno/adverts";
import { usePagination } from "@vgno/hooks";

import { Teasers } from "./Teasers";

import type { Article } from "@vgno/article";

export interface FeedProps {
  advertsInsertionRules?: {
    index: number;
    placementId: string;
    subtype: "netboard";
  }[];
  newsroom: string;
  nextPage: null | string;
  pathname: string;
  type: string;
}

const Feed = (props: FeedProps) => {
  const { advertsInsertionRules, newsroom, nextPage, pathname, type } = props;

  const params = new URLSearchParams({ newsroom, type });

  if (nextPage) {
    params.set("before", nextPage);
  }

  const { data, isFinished, isLoading, loadMoreRef } = usePagination<
    Article["teaser"]
  >(pathname, { params });

  useEffect(() => {
    const sectionFeed = document.querySelector('section[role="feed"]');
    if (sectionFeed) {
      sectionFeed.setAttribute("aria-busy", isLoading ? "true" : "false");
    }

    if (!isLoading) {
      updateAdvertory({}, { contextSelector: "#item-feed" });
    }
  }, [isLoading]);

  return (
    <>
      <Teasers advertsInsertionRules={advertsInsertionRules} articles={data} />
      {!isFinished && (
        <div
          className="spinner"
          ref={loadMoreRef as LegacyRef<HTMLDivElement>}
        />
      )}
    </>
  );
};

export default Feed;
